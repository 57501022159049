/**
* Generated automatically at built-time (2024-12-04T12:53:43.083Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-winecave-wine-cooler",templateKey: "sites/71-5034f3b1-6327-426d-973d-de59a7214e87"};