/**
* Generated automatically at built-time (2024-12-04T12:53:43.095Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-wineexpert-wine-cooler",templateKey: "sites/71-0e871826-3bab-4630-a2dc-3ad3556d3ce0"};